




































































































































































































































































































































































import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import Api from '@/includes/logic/Api'
import { UsersRawData } from './logic/usersStatTypes'
import UsersActivityHeatmap from '@/views/chat/statistics/components/mainStatistics/usersActivityHeatmap.vue'
import UsersLocales from './components/users/usersLocales.vue'
import UsersLogins from './components/users/usersLogins.vue'
import TopActiveUsers from './components/users/topActiveUsers.vue'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import TableUserActionButtons from '@/components/TableUserActionButtons.vue'
import { errorNotification } from '@/includes/NotificationService'
import PiTableHeader from "@/components/chat/PiTableHeader.vue";
import PermissionsBanner from "@/components/PermissionsBanner.vue";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";

import LineChart from 'piramis-base-components/src/components/Charts/LineChart/lineChart.vue'
import TableExportButtons from 'piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue'
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { tooltipTrigger } from 'piramis-base-components/src/shared/utils/tooltipTrigger'

import moment from 'moment'
import { Component, Mixins, Watch } from 'vue-property-decorator'

@Component({
  data() {
    return {
      SitePermissionEnum
    }
  },
  components: {
    PermissionsBanner,
    PiTableHeader,
    TableUserActionButtons,
    UserMainInfo,
    NotAvailableOptionsOverlay,
    UsersActivityHeatmap,
    PageTitle,
    UsersLocales,
    UsersLogins,
    TopActiveUsers,
    TableExportButtons,
    LineChart,
  },
  methods: {
    moment,
    tooltipTrigger
  },
})
export default class NewStatisticsUsers extends Mixins(TariffsTagsHelper) {
  loaded = false

  users: Array<{ [key: string]: string | number }> | null = null

  rawData: UsersRawData | null = null

  search = ''

  message_count = 0

  @Watch('$route.params.CHAT_ID')
  onChatChange() {
    this.users = null;
    this.rawData = null;
    this.getStat()
  }

  get needRequest() {
    return !this.getTagsByFieldKey('stat_users') && !this.$store.getters.botNotAdmin
  }

  get searchResult(): any {
    if (!this.search.length) {
      return this.users
    } else {
      return this.users!
        .filter(user => user.user_login && (user.user_login as string).toLowerCase().includes(this.search.trim().toLowerCase()) ||
          user.user_name && (user.user_name as string).toLowerCase().includes(this.search.trim().toLowerCase()))
    }
  }

  sortDaysWithMessages(a: any, b: any): number {
    if (a.days_with_messages && b.days_with_messages && a.first_message_days && b.first_message_days) {
      return parseFloat(this.calculateDaysWithMessages(b)) - parseFloat(this.calculateDaysWithMessages(a))
    }

    return -1
  }

  sortMessageCountByPeriod(a: any, b: any): number {
    if (a.message_count && b.message_count) {
      return parseInt(b.message_count) - parseInt(a.message_count)
    }

    return -1
  }

  getStat(): any {
    if (this.needRequest) {
      this.$baseTemplate.loader.open()
      const statAccessKey = this.$store.state.chatState.chat?.stat_access_key

      if (statAccessKey) {
        Api.getChatUsersStat(statAccessKey)
          .then(res => {
            this.message_count = res.data.message_count
            this.rawData = res.data
            this.users = this.getUsersTable()
          })
          .catch(errorNotification)
          .finally(() => {
            this.$baseTemplate.loader.close()
          })
      }
    }
  }

  calculateDaysWithMessages(record: any): string {
    const procent = (record.days_with_messages / record.first_message_days) * 100

    if (procent > 100) {
      return 100 + '%'
    }

    return procent.toFixed(2) + '%'

  }

  getUsersTable(): Array<any> | null {
    if (this.rawData) {
      const emptyMessageCount = this.rawData.users.filter((user) => !user.message_count)

      const sorted = this.rawData.users
        .filter((user) => user.message_count)
        .slice()
        .sort((a, b) => (b?.message_count ?? 0) - (a?.message_count ?? 0))

      sorted.push(...emptyMessageCount as any)

      return sorted
    }
    return null
  }

  mounted() {
    this.$baseTemplate.saveButton.hide()

    if (this.getTagsByFieldKey('stat_users') === null && this.$store.getters.canStatistics) {
      this.getStat()
    }
  }
}
